<template>
    <div v-if="client && portfolio" class="tl">
        <vxe-toolbar class="tl-header tl__mod-header">
            <template v-slot:buttons>
                <div class="tl__mod-header-title tl-header-title">
                    <div>Operations History</div>
                    <el-tooltip class="item" effect="light" content="Add Operation" placement="bottom">
                        <el-button type="text" @click="add_operation">
                            <svg-icon icon-class="plus" />
                        </el-button>
                    </el-tooltip>
                </div>
                <div class="tl__mod-header-subtitle">
                    <ClientHeaderLink :client="client" />
                    <PortfolioHeaderLink :portfolio="portfolio" :clientid="client.id" />
                </div>
            </template>
            <template v-slot:tools>
                <el-button type="text" style="margin-right:10px;" @click="get_list"><svg-icon icon-class="refresh" /></el-button>
                <PeriodPicker
                    :period_from="period_from"
                    :period_to="period_to"
                    @change="change_period" />
            </template>
        </vxe-toolbar>

        <div class="page-operations">
            <div class="page-operations-sidebar">
                <div class="operations-filter-block">
                    <div class="operations-filter-block-title">Operation Type</div>
                    <el-checkbox-group v-model="operation_types" class="operations-filter-checkbox_group" @change="get_list">
                        <el-checkbox label="sell">Sell</el-checkbox>
                        <el-checkbox label="sell_short">Sell Short</el-checkbox>
                        <el-checkbox label="buy">Buy</el-checkbox>
                        <el-checkbox label="cover_short">Cover Short</el-checkbox>
                        <el-checkbox label="deposit">Deposit</el-checkbox>
                        <el-checkbox label="withdraw">Withdraw</el-checkbox>
                        <el-checkbox label="dividend">Equity Dividend</el-checkbox>
                        <el-checkbox label="coupon">Fixed Income Coupon</el-checkbox>
                        <el-checkbox label="collateral_block">Collateral Block</el-checkbox>
                        <el-checkbox label="collateral_unblock">Collateral Unblock</el-checkbox>
                        <el-checkbox label="transfer">Transfer</el-checkbox>
                    </el-checkbox-group>
                </div>

                <div class="operations-filter-block">
                    <div class="operations-filter-block-title">Operation Asset</div>
                    <el-checkbox-group v-model="operation_assets" class="operations-filter-checkbox_group" @change="get_list">
                        <el-checkbox label="cash">Cash</el-checkbox>
                        <el-checkbox label="bond">Fixed Income</el-checkbox>
                        <el-checkbox label="equity">Equity</el-checkbox>
                        <el-checkbox label="future">Future</el-checkbox>
                        <el-checkbox label="option">Option</el-checkbox>
                    </el-checkbox-group>
                </div>

                <div class="operations-filter-block">
                    <div class="operations-filter-block-title">Operation Status</div>
                    <el-checkbox-group v-model="operation_status" class="operations-filter-checkbox_group" @change="get_list">
                        <el-checkbox label="actual">Actual</el-checkbox>
                        <el-checkbox label="del">Deleted</el-checkbox>
                    </el-checkbox-group>
                </div>
                <div class="spacer" />

                <div><el-button type="danger" plain @click="clear_filter">Clear</el-button></div>
            </div>

            <div class="page-operations-content">
                <vxe-grid
                    stripe
                    auto-resize
                    :data="list"
                    :columns="columns"
                    class="common-grid" 
                    v-loading="loading"
                    size="mini"
                    :expand-config="{lazy: true, loadMethod: load_content_method}"
                    :row-class-name="row_class_name"
                    ref="xGrid"
                    :menu-config="context_menu_config"
                    @cell-menu="cell_context_menu_event"
                    @menu-click="context_menu_click_event"
                    highlight-current-row>
                    
                    <template v-slot:col_opertype="{ row, column  }">
                        <span v-if="row.t">{{row.t.replace(/_/g, " ")}}</span>
                    </template>

                    <template v-slot:col_instrument="{ row, column  }">
                        <span v-if="['future', 'option'].includes(row.asset)">{{row.dscode}}</span>
                        <span v-else>{{row.isin}}</span>
                    </template>

                    <template v-slot:col_num="{ row, column  }">
                        <FormattedNumber
                            :value="row[column.property]"
                            :decimals="column.params.format.decimals"/>
                    </template>

                    <template  v-slot:expandable="{ row }">
                        <div class="operations-expand-holder">
                            <div class="operations-expand-descr">
                                <OperationDescription 
                                    :operation="row" 
                                    :portfolio-currency="portfolio.currency"
                                    @delete-operation="delete_operation(row)"
                                    @edit-operation="edit_operation(row)"
                                    @copy-operation="copy_operation(row)"
                                     />
                            </div>
                            <div class="spacer">
                                <OperationAccountTransactions :list="row.account_transactions" :accounts="accounts" />
                                <OperationCostTransactions :list="row.cost_transactions" />
                                <OperationVersions :list="row.versions" />

                                <div class="operations-expand-close">
                                    <el-button type="primary" @click="toggle_expand(row)">Close</el-button>
                                </div>
                            </div>
                        </div>
                    </template>
                </vxe-grid>
            </div>
        </div>
    </div>
</template>

<script>
import PeriodPicker from '@/components/PageComponents/PeriodPicker'
import ClientHeaderLink from '@/components/PageComponents/ClientHeaderLink'
import PortfolioHeaderLink from '@/components/PageComponents/PortfolioHeaderLink'

import FormattedNumber from '@/components/Formats/FormattedNumber'

import OperationDescription from './OperationDescription'
import OperationAccountTransactions from './OperationAccountTransactions'
import OperationCostTransactions from './OperationCostTransactions'
import OperationVersions from './OperationVersions'

export default {
    name: 'operations',
    components: { 
        PeriodPicker, 
        ClientHeaderLink, 
        PortfolioHeaderLink, 
        FormattedNumber,
        OperationDescription, 
        OperationAccountTransactions, 
        OperationCostTransactions, 
        OperationVersions, 
    },

    props: {
        clientid: {
            required: true,
        },
        portfolioid: {
            required: true,
        },
    },

    data(){
        return {
            client           : undefined,
            portfolio        : undefined,
            loading          : false,
            list             : [],
            period_from      : this.$moment().add({months:-3}).toDate(),
            period_to        : this.$moment().toDate(),
            operation_types  : ['sell','sell_short','buy','cover_short','deposit','withdraw'],
            operation_assets : [],
            operation_status : ['actual'],
            accounts         : [],
            columns: [
                {
                    type  : 'expand',
                    width : 30,
                    slots : { content: 'expandable' },
                },{
                    field : 'opvid',
                    title : 'ID',
                    width : 60,
                },{
                    field : 'opsubid',
                    title : 'Version',
                    width : 67,
                },{
                    field     : 'tdt',
                    title     : 'Trade Date',
                    width     : 90,
                    formatter : "formatDateTime",
                },{
                    field     : 'vdt',
                    title     : 'Value Date',
                    width     : 90,
                    formatter : "formatDate",
                },{
                    field    : 'asset',
                    title    : 'Operation Asset',
                    minWidth : 100,
                },{
                    field    : 't',
                    title    : 'Operation Type',
                    minWidth : 100,
                    slots    : { default: 'col_opertype' },
                },{
                    field    : 'isin',
                    title    : 'Instrument',
                    minWidth : 110,
                    slots    : { default: 'col_instrument' },
                },{
                    field : 'tcur',
                    title : 'Currency',
                    width : 100,
                },{
                    field    : 'tpr',
                    title    : 'Unit Price',
                    minWidth : 100,
                    align: 'right',
                    slots    : { default: 'col_num' },
                    params: {format : {
                        decimals  : 8,
                    }}
                },{
                    field    : 'q',
                    title    : 'Quantity',
                    minWidth : 100,
                    align: 'right',
                    slots    : { default: 'col_num' },
                    params: {format : {
                        decimals  : 2,
                    }}
                }
            ],
        }
    },

    computed: {
        context_menu_config(){
            return {
                body: {
                    options: [
                        [
                            { code: 'edit', name: 'Edit Operation', visible: true, disabled: false },
                            { code: 'copy', name: 'Copy Operation', visible: true, disabled: false },
                        ],[
                            { code: 'del', name: 'Delete Operation', visible: true, disabled: false, className:'red' },
                        ]
                    ]
                },
                visibleMethod: this.context_menu_visible_method
            }
        }
    },

    methods: {
        get_list(){
            this.loading = true
            let search_params = {
                portfolio_id : this.portfolio._id,
                client_id    : this.client._id,
                date_from    : this.period_from,
                date_to      : this.period_to,
            }
            if (this.operation_types.length){
                search_params.t = {'$in': this.operation_types}
            }
            if (this.operation_assets.length){
                search_params.asset = {'$in': this.operation_assets}
            }
            if (this.operation_status.length){
                search_params.status = {'$in': this.operation_status}
            }
            this.$store.dispatch('portfolioOperations/getList', search_params).then((response) => {
                this.list = response
                this.loading = false
            })
        },

        change_period($event){
            this.period_from = $event[0];
            this.period_to   = $event[1];

            this.$nextTick(function () {
                this.get_list()
            })
        },

        clear_filter(){
            if(
                this.operation_types.length ||
                this.operation_assets.length ||
                this.operation_status.length
            ) {
                this.operation_types  = []
                this.operation_assets = []
                this.operation_status = []
                this.get_list()
            }
        },

        load_content_method({ row }){return new Promise(resolve => {
                this.$store.dispatch('portfolioOperations/getVersionsList', {
                    client_id : this.client._id,
                    opid      : row.opid,
                }).then((response) => {
                    row.versions = response
                     this.$store.dispatch('portfolioOperations/getTransactionsList', {
                            client_id : this.client._id,
                            op_id     : row._id,
                            mode      : 'trade',
                        }).then((response) => {
                            row.account_transactions = response.accounts_data
                            row.cost_transactions = response.method_data
                            resolve()
                        })
                })
            })
        },

        row_class_name({coluns, row}) {
            if (row.status === 'del')
                return 'operations-deleted-row_class'
            return ''
        },

        toggle_expand(row){
            this.$refs.xGrid.toggleRowExpand(row);
        },

        context_menu_visible_method({ type, options, column, row }){
            const $grid = this.$refs.xGrid

            options.forEach(list => {
                list.forEach(item => {
                    if (['del', 'edit'].includes(item.code) && row.status === 'del') {
                        item.disabled = true
                    }
                })
            })

            return true
        },

        cell_context_menu_event({ row }){
            const $grid = this.$refs.xGrid
            $grid.setCurrentRow(row)
        },

        context_menu_click_event({ menu, row, column }){
            const $grid = this.$refs.xGrid
            switch (menu.code) {
                case 'del':
                    this.delete_operation(row)
                    break
                case 'edit':
                    this.edit_operation(row)
                    break
                case 'copy':
                    this.copy_operation(row)
                    break
            }
        },

        delete_operation(row){
            this.$prompt('Please input the reason you want to delete this operation', 'Tip', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                inputPattern: /^(?!\s*$).+/,
                inputErrorMessage: 'The reason cannot be empty'
            }).then(({ value }) => {
                const recalculate_loading = this.$loading({
                    lock: true,
                    text: 'Recalculating Portfolio Data',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                this.$store.dispatch('portfolioOperations/deleteItem', {
                    'id'        : row._id,
                    'client_id' : this.client._id,
                    'reason'    : value
                }).then(() => {
                    recalculate_loading.close();
                    this.get_list();
                    this.$emit('update-portfolio-data', { clientid: this.client.id, portfolioid: row.portfolio_id })
                });
            }).catch(() => {});
        },

        edit_operation(row){
            this.$router.push('/operation/' + this.clientid + '/' + row.portfolio_id + '/' + row._id)
        },

        copy_operation(row) {
            this.$message('Will be implemented soon!')
        },

        add_operation(){
            this.$router.push('/new-operation/' + this.clientid + '/' + this.portfolio._id)
        },
    },

    mounted(){
        if (this.clientid && this.portfolioid) {
            this.$store.dispatch('clients/getClient', this.clientid).then((client_response) => {
                this.client = client_response
                if (!this.client) return;
                this.$store.dispatch('portfolio/getPortfolio', this.portfolioid).then((portfolio_response) => {
                    this.portfolio = portfolio_response
                    if (!this.portfolio) return;

                    this.$emit('tab-title-change', 'Operations in ' + this.portfolio.name + ' for ' + this.client.first_name + ' ' + this.client.last_name)

                    this.$store.dispatch('portfolioAccounts/getList', {
                        portfolio_id : this.portfolio._id,
                    }).then((response) => {
                        this.accounts = response
                    })

                    this.get_list()
                })
            })
        }
    }
}
</script>
