<template>
    <div class="operation-text-description">
        <div class="otd-botmargin-8"><TransactionText :operation="operation" :container_cur="portfolioCurrency" /></div>
        <div class="otd-botmargin-8">Portfolio Cur. Rate: {{operation.tcur_prate}}</div>
        <div class="otd-botmargin-8" v-if="operation.strategy_id">Strategy Cur. Rate: {{operation.tcur_srate}}</div>
        <div class="otd-botmargin-8" v-if="operation.fees.length">
            <div class="bold">Charges:</div>
            <div v-for="fee in operation.fees">{{fee.am}} {{fee.cur}}</div>
        </div>
        <div class="otd-botmargin-8" v-if="operation.status === 'del'">
            Reason for deletion: {{operation.cmnt}}
        </div>
        <div class="otd-botmargin-8 otd-topmargin-16">Operation ID: {{operation.opid}}</div>
        <div class="otd-botmargin-8">Operation Version: {{operation.opsubid}}</div>
        <div class="otd-botmargin-8" v-if="operation.status !== 'del'">
            Create/Edit Date: {{format_date(operation.cdt, 'DD MMM YY HH:mm:ss ZZ')}}
        </div>
        <div class="otd-botmargin-8" v-else>
            Deletion Date: {{format_date(operation.cdt, 'DD MMM YY HH:mm:ss ZZ')}}
        </div>
        <div class="otd-botmargin-8" v-if="operation.status !== 'del'">
            Created/edited by: {{operation.cun}}, {{operation.cip}}
        </div>
        <div class="otd-botmargin-8" v-else>
            Deleted by: {{operation.cun}}, {{operation.cip}}
        </div>

        <div class="otd-botmargin-8 otd-topmargin-16">
            <el-button type="primary" plain class="otd-edit-btn" @click="edit_operation">
                Edit
            </el-button>
            <el-button type="success" plain class="otd-copy-btn" @click="copy_operation">
                Copy
            </el-button>
            <el-button type="danger" plain class="otd-del-btn" @click="delete_operation">
                Delete
            </el-button>
        </div>
    </div>
</template>

<script>
import TransactionText from '@/components/PageComponents/TransactionText'
import { date_to_string } from '@/utils'

export default {
    components: { TransactionText },

    props: {
        operation: {
            required: true,
            type: Object
        },
        portfolioCurrency: {
            required: true,
            type: String
        }
    },

    methods: {
        edit_operation(){
            this.$emit('edit-operation')
        },
        copy_operation(){
            this.$emit('copy-operation')
        },
        delete_operation(){
            this.$emit('delete-operation')
        },
        format_date(val, format) {
            return date_to_string(val, format)
        }
    }
}
</script>
