<template>
    <div>
        <div class="operations-expand-block-name">Cost Transactions</div>
        <vxe-table 
            :data="list" 
            size="mini" 
            class="common-grid"
            border
            round
            :row-class-name="row_class_name">
                <vxe-table-column type="seq" width="40"></vxe-table-column>
                <vxe-table-column field="isin" width="120" title="ISIN"></vxe-table-column>
                <vxe-table-column field="p" width="120" title="Price" align="right">
                    <template v-slot="{ row }">
                        <FormattedNumber :value="row.p" :decimals="8" />
                    </template>
                </vxe-table-column>
                <vxe-table-column field="q" width="120" title="Volume" align="right">
                    <template v-slot="{ row }">
                        <FormattedNumber :value="row.q" :decimals="2" color sign />
                    </template>
                </vxe-table-column>
                <vxe-table-column field="p_t" title="Position Type"></vxe-table-column>
                <vxe-table-column field="flag" title="Transaction Flag">
                    <template v-slot="{ row }">
                        <span v-if="row.flag && row.flag === 'cms'">comission</span>
                        <span v-else>{{row.f}}</span>
                    </template>
                </vxe-table-column>
        </vxe-table>
    </div>
</template>

<script>
import FormattedNumber from '@/components/Formats/FormattedNumber'
export default {
    components: { FormattedNumber },

    props: {
        list: {
            required: true,
            type: Array
        }
    },

    methods: {
        row_class_name({ column, row }){
            if (row.q < 0)
                return 'col-credit';
            else
                return 'col-debit';
        },
    }
}
</script>
>
